// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recipe-details {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    font-size: 16px;
    line-height: 1.5;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}

/* Additional styles for emoji rendering and text fitting */
.recipe-meta span {
  display: inline-block;
  margin-right: 10px;
  font-size: 1rem;
  line-height: 1.5;
}

.tag {
  display: inline-block;
  background-color: #e9ecef;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
}

.recipe-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.recipe-content {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,eAAe;AACnB;;AAEA,2DAA2D;AAC3D;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".recipe-details {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: white;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    position: relative;\n    font-size: 16px;\n    line-height: 1.5;\n}\n\n.close-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    font-size: 24px;\n    background: none;\n    border: none;\n    cursor: pointer;\n}\n\n/* Additional styles for emoji rendering and text fitting */\n.recipe-meta span {\n  display: inline-block;\n  margin-right: 10px;\n  font-size: 1rem;\n  line-height: 1.5;\n}\n\n.tag {\n  display: inline-block;\n  background-color: #e9ecef;\n  border-radius: 5px;\n  padding: 5px 10px;\n  margin: 5px;\n}\n\n.recipe-image {\n  width: 100%;\n  height: auto;\n  object-fit: cover;\n}\n\n.recipe-content {\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
