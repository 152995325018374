import './App.css';
import './styles.css';
import React, { useEffect, useState } from 'react';

function RecipeDetails({ recipe, onClose }) {
  return (
    <div className="recipe-details">
      <button className="close-button" onClick={onClose}>
        ❌
      </button>
      <h2>{recipe.name}</h2>
      <img src={recipe.image} alt={recipe.name} className="recipe-image" />
      <div className="details-grid">
        <p><strong>Cuisine:</strong> {recipe.cuisine}</p>
        <p><strong>Difficulty:</strong> {recipe.difficulty}</p>
        <p><strong>Prep Time:</strong> {recipe.prepTimeMinutes} minutes</p>
        <p><strong>Cook Time:</strong> {recipe.cookTimeMinutes} minutes</p>
        <p><strong>Servings:</strong> {recipe.servings}</p>
        <p><strong>Calories:</strong> {recipe.caloriesPerServing} per serving</p>
      </div>
      <h3>Ingredients:</h3>
      <ul>
        {recipe.ingredients && recipe.ingredients.length > 0 ? recipe.ingredients.map((ingredient, index) => (
          <li key={index}>{ingredient}</li>
        )) : <li>No ingredients available</li>}
      </ul>
      <h3>Instructions:</h3>
      <ol>
        {recipe.instructions && recipe.instructions.length > 0 ? recipe.instructions.map((instruction, index) => (
          <li key={index}>{instruction}</li>
        )) : <li>No instructions available</li>}
      </ol>
    </div>
  );
}

function RecipeAddForm({ onAdd }) {
  const [name, setName] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [instructions, setInstructions] = useState('');
  const [image, setImage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newRecipe = {
      name,
      ingredients: ingredients.split(',').map(ingredient => ingredient.trim()),
      instructions: instructions.split('.').map(instruction => instruction.trim()),
      image,
    };
    onAdd(newRecipe);
    setName('');
    setIngredients('');
    setInstructions('');
    setImage(null);
  };

  return (
    <form onSubmit={handleSubmit} className="recipe-add-form">
      <input type="text" placeholder="Recipe Name" value={name} onChange={(e) => setName(e.target.value)} required />
      <textarea placeholder="Ingredients (comma separated)" value={ingredients} onChange={(e) => setIngredients(e.target.value)} required />
      <textarea placeholder="Instructions (separate with periods)" value={instructions} onChange={(e) => setInstructions(e.target.value)} required />
      <input type="file" accept="image/*" onChange={(e) => setImage(URL.createObjectURL(e.target.files[0]))} />
      <button type="submit">Add</button>
    </form>
  );
}

function App() {
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await fetch('https://dummyjson.com/recipes');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setRecipes(data.recipes);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchRecipes();
  }, []);

  const handleAddRecipe = (newRecipe) => {
    setRecipes([...recipes, newRecipe]);
  };

  const handleDeleteRecipe = (recipeId) => {
    setRecipes(recipes.filter(recipe => recipe.id !== recipeId));
  };

  const filteredRecipes = recipes.filter(recipe => recipe.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const toggleAddForm = () => {
    setShowAddForm(prev => !prev);
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="App">
      <header className="app-header">
        <h1>Gourmet Recipe Book</h1>
        <input
          type="text"
          placeholder="Search recipes..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
        <button onClick={toggleAddForm} className="add-recipe-button">
          ➕
        </button>
      </header>
      {showAddForm && <RecipeAddForm onAdd={handleAddRecipe} />}
      {selectedRecipe ? (
        <RecipeDetails recipe={selectedRecipe} onClose={() => setSelectedRecipe(null)} />
      ) : (
        <div className="recipe-grid">
          {filteredRecipes.map((recipe) => (
            <div className="recipe-card" key={recipe.id} onClick={() => setSelectedRecipe(recipe)}>
              <div className="recipe-image-container">
                <img src={recipe.image} alt={recipe.name} className="recipe-image" />
              </div>
              <div className="recipe-content">
                <h2>{recipe.name}</h2>
                <div className="recipe-meta">
                  <span>🍳 Prep: {recipe.prepTimeMinutes}min</span>
                  <span>🍲 Cook: {recipe.cookTimeMinutes}min</span>
                  <span>👨‍🍳 Servings: {recipe.servings}</span>
                </div>
                <p className="recipe-calories">Calories: {recipe.caloriesPerServing} per serving</p>
                <div className="recipe-tags">
                  {recipe.tags && recipe.tags.length > 0 ? recipe.tags.map((tag, index) => (
                    <span key={index} className="tag">{tag}</span>
                  )) : <span>No tags available</span>}
                </div>
                <button onClick={() => handleDeleteRecipe(recipe.id)} className="delete-button">
                  🗑️
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default App;